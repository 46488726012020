import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import { Map } from 'immutable'

import rootReducer from './reducers'

const configureStore = () => {

  const initialState = new Map();

  let store = createStore(
    rootReducer,
    initialState,
    compose(
      applyMiddleware(
        thunk
      )
    )
  );

  if (process.env.NODE_ENV === 'development') {
    store = createStore(
      rootReducer,
      initialState,
      compose(
        applyMiddleware(
          thunk
        ),
        window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
      )
    );
  }

  return store
};

export default configureStore
