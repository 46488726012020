import { WS_URL } from 'config'

export class Websocket {

  constructor(url, cb) {
    this.open(url, cb);
  }

  open(url, cb) {
    this.instance = new WebSocket(`${WS_URL}${url}?token=${localStorage.getItem('token')}`);
    this.instance.onopen    = e => this.onOpen(e);
    this.instance.onclose   = e => this.onClose(e, url, cb);
    this.instance.onmessage = e => this.onMessage(e, cb);
    this.instance.onerror   = e => this.onError(e);
  }

  close() {
    if (this.instance) {
      this.instance.onclose = () => console.info('Socket was closed by you');
      this.instance.close();
      delete this.instance;
    }
  }

  onOpen() {}

  onClose(e, url, cb) {
    setTimeout(() => this.open(url, cb), 2000);
  }

  onError(e) {
    return console.error(e);
  }

  onMessage(e, cb) {
    return cb(JSON.parse(e.data))
  }
}
