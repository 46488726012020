import React, { Component } from "react";
import connect from "react-redux/es/connect/connect";
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {setLanguage} from "redux-i18n";
import { setDefaultLocale } from "react-datepicker";

import LangButton from './LangButton';

class ProfileMenu extends Component {

  logout = () => {
    localStorage.removeItem('token');
    window.location.replace('/');
  };

  setLang = (lang) => {
    localStorage.setItem('language', lang);
    setDefaultLocale(lang);
    this.props.dispatch(setLanguage(lang))
  };

  render() {

    const { auth, app } = this.props;

    let menuClass = classNames({
      'profile-menu': true,
      'profile-menu__show': app.get('show_user_menu')
    });

    return(
      <div className={menuClass}>
        <div className="profile-menu__header">
          <figure className="avatar medium initials">
            <span>{auth.getIn(['user', 'display_initial'])}</span>
          </figure>
          <div className="user-info">
            <span className="name">{auth.getIn(['user', 'display_name'])}</span>
            <span className="displayname">{auth.getIn(['user', 'email'])}</span>
          </div>
          <div className="actions">
            <button onClick={ this.logout }>{this.context.t("logout")}</button>
            {auth.get('user') && auth.getIn(['user', 'is_admin']) &&
              <Link to="/admin/">{this.context.t("admin")}</Link>
            }
          </div>
        </div>
        <div className="language">
          <ul className="btn-list">
            <li><LangButton lang='uk' display='УКР' setLang={this.setLang} /></li>
            <li><LangButton lang='en' display='ENG' setLang={this.setLang} /></li>
            <li><LangButton lang='ru' display='РУС' setLang={this.setLang} /></li>
          </ul>
        </div>
      </div>
    )
  }
}

ProfileMenu.contextTypes = {
  t: PropTypes.func
};

function mapStateToProps(state) {
  return {
    auth: state.get('auth'),
    app: state.get('app')
  }
}

export default connect(mapStateToProps)(ProfileMenu)
