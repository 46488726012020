import { Map } from "immutable";
import { TOGGLE_USER_MENU, TOGGLE_SUBNAV_SEARCH, TOGGLE_NEWS_FILTER, RESET_APP } from 'state/actions/app';

const initailState = new Map({
  show_user_menu: false,
  show_subnav_search: false
});

export function app(state=initailState, action) {
  switch (action.type) {
    case TOGGLE_USER_MENU:
      return state.merge({
        show_user_menu: !state.get('show_user_menu')
      });
    case TOGGLE_SUBNAV_SEARCH:
      return state.merge({
        show_subnav_search: !state.get('show_subnav_search')
      });
    case TOGGLE_NEWS_FILTER:
      return state.merge({
        show_news_filter: !state.get('show_news_filter')
      });
    case RESET_APP:
      return initailState;
    
    default:
      return state;
  }
}
