import React, { Component } from 'react'
import { connect } from 'react-redux';
import  * as actions  from 'state/actions/client'
import ClientForm from './ClientForm'
import { ClientUsersList } from "./ClientUsersList";
import { ClientProductsList } from './ClientProductsList'

class ClientPage extends Component {

  componentWillMount() {
    this.props.fetchClient(this.props.match.params.uuid)
  }

  render() {

    const uuid = this.props.match.params.uuid;
    const client = this.props.client.getIn(['data', 'entities', 'clients', uuid]);

    if (!client) {
      return(
        <div>Loading...</div>
      )
    } else {
      return(
        <div>
          <div className="row">
            <div className="col-md-12">
              <div className='sd-list-header'>
                <div className='sd-list-header__name'>
                  <span>{client.get('name')}</span>
                </div>
              </div>
            </div>
          </div>
          <ClientForm initialValues={client} />
          <div className="row">
            <div className="col-md-12">
              <div className='sd-list-header'>
                <div className='sd-list-header__name'>
                  <span>Products</span>
                </div>
              </div>
            </div>
          </div>
          <ClientProductsList products={client.get('products')} />
          <div className="row">
            <div className="col-md-12">
              <div className='sd-list-header'>
                <div className='sd-list-header__name'>
                  <span>Users</span>
                </div>
              </div>
            </div>
          </div>
          <ClientUsersList users={client.get('users')} />
        </div>
      )
    }
  }
}

function mapStateToProps(state) {
  return {
    client: state.get('client')
  }
}

export default connect(mapStateToProps, actions)(ClientPage)
