import {api} from "config";
import {normalize, schema} from "normalizr";

export const REQUEST_CLIENTS = 'request_clients';
export const RECEIVE_CLIENTS = 'receive_clients';
export const FAIL_CLIENTS = 'fail_clients';
export const CLEAN_CLIENTS = 'clean_clients';

const users = new schema.Entity(
  'users',
  {},
  { idAttribute: 'uuid' });

const products = new schema.Entity(
  'products',
  {},
  { idAttribute: 'uuid' });

const clients = new schema.Entity(
  'clients',
  {users: [users], products: [products],},
  { idAttribute: 'uuid' });


export const fetchClients = () => async dispatch => {
  dispatch({
    type: REQUEST_CLIENTS
  });

  try {
    const response = await api.get('/client/');
    dispatch({
      type: RECEIVE_CLIENTS,
      payload: normalize(response.data, [clients])
    });
  } catch (error) {
    dispatch({
      type: FAIL_CLIENTS,
      payload: error.response.data
    });
  }
};

export const cleanClients = () => dispatch => {
  dispatch({
    type: CLEAN_CLIENTS
  });
};
