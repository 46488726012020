import axios from "axios";
import moment from 'moment';
import 'moment/min/locales'

export const API_URL = process.env.NODE_ENV === 'development' ? 'http://localhost:8080/api' : '/api';
export const WS_URL = process.env.NODE_ENV === 'development' ? 'ws://localhost:8080/ws' : 'wss://newshub.ukrinform.com/ws';
export const DEFAULT_LANGUAGE = 'uk';

export const api = axios.create({
  baseURL: API_URL,
  headers: {'Authorization': `Token ${localStorage.getItem('token')}`}
});

api.defaults.params = {};
api.defaults.params[ 'language' ] = localStorage.getItem('language');

const locale = window.navigator.userLanguage || window.navigator.language;
moment.locale(locale);
