import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch} from 'react-router-dom'
import { Provider } from 'react-redux';
import I18n from "redux-i18n/immutable"
import { translations } from "translations"
import * as serviceWorker from './serviceWorker';
import configureStore from 'state/store';
import { DEFAULT_LANGUAGE } from 'config';
import './styles/index.scss';
import './styles/datepicker/datepicker.scss';

import { Main } from 'main'
import { LoginPage, AuthRoute } from 'pages/login'
import { ProductsPage } from 'pages/products'

import { registerLocale, setDefaultLocale } from "react-datepicker";
import {enUS, uk, ru} from 'date-fns/esm/locale'
registerLocale('en', enUS);
registerLocale('ru', ru);
registerLocale('uk', uk);

const store = configureStore();

const language = () => {
  if (localStorage.getItem('language')) {
    return localStorage.getItem('language')
  } else {
    localStorage.setItem('language', DEFAULT_LANGUAGE);
    return DEFAULT_LANGUAGE
  }
};


setDefaultLocale(language());

ReactDOM.render(
  <Provider store={store}>
    <I18n translations={translations} initialLang={ language() }>
      <BrowserRouter>
        <Switch>
          <Route path='/login' component={ LoginPage }/>
          <Route path='/products' component={ ProductsPage }/>
          <AuthRoute path='/' component={ Main }/>
        </Switch>
      </BrowserRouter>
    </I18n>
  </Provider>,
  document.getElementById('root')
);
serviceWorker.unregister();
