import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { API_URL } from 'config';

import * as actions from 'state/actions/product'
import ReactTooltip from "react-tooltip";

class ModalNews extends Component{

  componentDidMount() {
    ReactTooltip.rebuild();
  }

  closeModal() {
    this.props.closeNewsModal()
  }

  closePrint() {
    document.body.removeChild(this.__container__);
  }

  setPrint () {
    this.contentWindow.__container__ = this;
    this.contentWindow.onbeforeunload = this.closePrint;
    this.contentWindow.onafterprint = this.closePrint;
    this.contentWindow.focus(); // Required for IE
    this.contentWindow.print();
  }

  printNews(uuid) {
    const url = `${API_URL}/news/${uuid}/print/?token=${localStorage.getItem('token')}`;

    let oHiddFrame = document.createElement("iframe");
    oHiddFrame.onload = this.setPrint;
    oHiddFrame.style.position = "fixed";
    oHiddFrame.style.right = "0";
    oHiddFrame.style.bottom = "0";
    oHiddFrame.style.width = "0";
    oHiddFrame.style.height = "0";
    oHiddFrame.style.border = "0";
    oHiddFrame.src = url;
    document.body.appendChild(oHiddFrame);
  }

  render() {

    const { news, closeModal } = this.props;

    const url = `${API_URL}/news/${news.get('uuid')}/pdf/?token=${localStorage.getItem('token')}`;

    return(
      <div>
        {news &&
          <div>
            <div className='modal modal--fill in'>
              <div className='modal__dialog'>
                <div className='modal__content'>
                  <div className="modal__header">
                    <button className="modal__close pull-right" onClick={() => closeModal()}>
                      <i className="icon-close-small" />
                    </button>
                    <h3 className="modal__heading">{ news.get('headline') }</h3>
                    <time>
                      {new Intl.DateTimeFormat('uk', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                        hour: '2-digit',
                        minute: '2-digit'
                      }).format(Date.parse(news.get('version_created')))}
                    </time>
                    {news.get('attachments') && !!news.get('attachments').size &&
                      <div>
                        <h4>Attachments</h4>
                        {news.get('attachments').map(n =>
                            <a key={n.get('superdesk_guid')} target="_blank" rel="noopener noreferrer" style={{marginRight: '20px'}} href={ n.get('url') }>{ n.get('name') }</a>
                        )}
                      </div>
                    }
                  </div>
                  <div className="modal__body">
                    <div className='text-wrap' dangerouslySetInnerHTML={{__html: news.get('body_html_modify')}} />
                    {news.get('byline') && <p dangerouslySetInnerHTML={{__html: news.get('byline')}} />}
                  </div>
                  <div className="modal__footer">
                    <a href={url} data-tip={this.context.t("download")} className="btn btn--primary btn--icon-only btn--hollow">
                      <i className="icon-download"/>
                    </a>
                    <button data-tip={this.context.t("print")} className="btn btn--primary btn--icon-only btn--hollow" onClick={() => this.printNews(news.get('uuid'))}>
                      <i className="icon-print"/>
                    </button>
                    <button className="btn btn--primary" onClick={() => closeModal()}>{this.context.t("close")}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    )
  }

}

ModalNews.contextTypes = {
  t: PropTypes.func
};

function mapStateToProps(state) {
  return { products: state.get('products') }
}

export default connect(mapStateToProps, actions)(ModalNews)
