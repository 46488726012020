import { Map, fromJS } from 'immutable'

import {
  REQUEST_PRODUCT,
  RECEIVE_PRODUCT,
  FAIL_PRODUCT,
  ADD_NEWS_TO_PRODUCT,
  REMOVE_NEWS_FROM_PRODUCT,
  UPDATE_NEWS_IN_PRODUCT,
  CLEAN_PRODUCT
} from 'state/actions/product';

const initailState = new Map({
  loading: false
});

export function product(state=initailState, action) {
  switch (action.type) {
    case ADD_NEWS_TO_PRODUCT:
      if (
        action.payload.product === state.getIn(['data', 'entities', 'products', action.payload.product, 'uuid'])) {
        return state.setIn(['data', 'entities', 'news', action.payload.news.uuid], Map(fromJS(action.payload.news)))
        .updateIn(['data', 'entities', 'products', action.payload.product, 'news'], list => list.insert(0, action.payload.news.uuid))
      } else {
        return state
      }
    case REMOVE_NEWS_FROM_PRODUCT:
      if (
        action.payload.product === state.getIn(['data', 'entities', 'products', action.payload.product, 'uuid'])) {
        return state.updateIn(['data', 'entities', 'products', action.payload.product, 'news'], list => list.filter(news => news !== action.payload.news))
      } else {
        return state
      }
    case UPDATE_NEWS_IN_PRODUCT:
      if (
        action.payload.product === state.getIn(['data', 'entities', 'products', action.payload.product, 'uuid'])) {
        return state.updateIn(['data', 'entities', 'news', action.payload.news.uuid], news => Map(fromJS(action.payload.news)))
      } else {
        return state
      }
    case REQUEST_PRODUCT:
      return state.merge({
        loading: true
      });
    case RECEIVE_PRODUCT:
      return state.merge({
        loading: false,
        data: action.payload
      });
    case FAIL_PRODUCT:
      return state.merge({
        loading: false,
        error: action.payload
      });
    case CLEAN_PRODUCT:
      return initailState;
    default:
      return state
  }
}
