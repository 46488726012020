import React from 'react'

export const ProductItem = ({ product, number }) => (
  <tr>
    <td>{number + 1}</td>
    <td>{product.get('name')}</td>
    <td>{product.get('description')}</td>
    <td>{product.get('periodical')}</td>
    <td>{product.get('amount')}</td>
    <td>{product.get('transmission')}</td>
    <td>{product.get('language')}</td>
  </tr>
);