import React, { Component } from "react";
import PropTypes from 'prop-types';
import classNames from "classnames";
import {Link} from "react-router-dom";

export default class ProductSelect extends Component {

  constructor(props) {
    super(props);
    this.state = {
      open: false
    };

  }

  toggleSelect = () => this.setState({open: !this.state.open});

  render() {

    let toggleClass = classNames({
      'product-select-list': true,
      'product-select-list--active': this.state.open
    });

    const { current, list } = this.props;

    return(
      <div className='product-select'>
        <div
          className='product-select-current'
          onClick={() => this.toggleSelect()}
        >
          {current} <i className="icon-chevron-down-thin"/>
        </div>
        <ul className={toggleClass}>
          <li><Link to='/' >{this.context.t("all products")}</Link></li>
          {list.get('data') && list.getIn(['data', 'result']).map(product =>
          <li key={product}><Link to={`/product/${product}`} >{ list.getIn(['data', 'entities', 'products', product, 'name']) }</Link></li>
          )}
        </ul>
      </div>
    )
  }
}

ProductSelect.contextTypes = {
  t: PropTypes.func
};

