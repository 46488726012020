export const translations = {
  'ru': {
    'UKRINFORM': 'УКРИНФОРМ',
    'logout': 'выйти',
    'admin': 'администратор',
    'prepaid products': 'предоплаченные продукты',
    'password': 'пароль',
    'sign in': 'вход',
    'download': 'скачать',
    'print': 'печать',
    'close': 'закрыть',
    'published from:': 'опубликовано с:',
    'published to:': 'опубликовано до:',
    'reset': 'сбросить',
    'apply': 'применить',
    'product:': 'продукт:',
    'all products': 'все продукты',
    'more news': 'больше новостей',
    'last day': 'последние сутки',
    'last week': 'последняя неделя',
    'last month': 'последний месяц',
    'search': 'поиск',
    'Found:': 'Найдено:',
    'Your search did not match any news': 'По вашему запросу ничего не найдено',
    'Try more general keywords or change dates': 'Попробуйте использовать более общие ключевые слова или измените даты',
  },
  'options': {
    'plural_rule': 'n%10==1 && n%100!=11 ? 0 : n%10 >= 2 && n%10<=4 &&(n%100<10||n%100 >= 20)? 1 : 2',
    'plural_number': '3',
  },
  'uk': {
    'UKRINFORM': 'УКРІНФОРМ',
    'logout': 'вихід',
    'admin': 'адміністратор',
    'prepaid products': 'передплатні продукти',
    'password': 'пароль',
    'sign in': 'вхід',
    'download': 'завантажити',
    'print': 'друкувати',
    'close': 'закрити',
    'published from:': 'опубліковано з:',
    'published to:': 'опубліковано до:',
    'reset': 'скасувати',
    'apply': 'застосовувати',
    'product:': 'продукт:',
    'all products': 'всі продукти',
    'more news': 'більше новин',
    'last day': 'остання доба',
    'last week': 'останній тиждень',
    'last month': 'останній місяць',
    'search': 'пошук',
    'Found:': 'Знайдено:',
    'Your search did not match any news': 'На ваш запит не знайдено жодної новини',
    'Try more general keywords or change dates': 'Спробуйте використати загальніші слова або змінити дати',
  },
}
