import { Map, List } from "immutable";

import { 
  ADD_START_DATE_QUERY, 
  ADD_END_DATE_QUERY, 
  ADD_PRODUCT_QUERY, 
  REMOVE_PRODUCT_QUERY, 
  ADD_SEARCH_QUERY,
  RESET_SEARCH_QUERY,
  REQUEST_SEARCH,
  RECEIVE_SEARCH,
  FAIL_SEARCH,
  CLEAN_SEARCH
} from 'state/actions/search';

const initailState = new Map({
  params: new Map({
    search_query: '',
    start_date: null,
    end_date: null,
    products: List()
  }),
  loading: false,
  data: null,
});
  
export function search(state = initailState, action) {
    switch (action.type) {
      case ADD_START_DATE_QUERY:
        return state.setIn(['params', 'start_date'], action.date);
      case ADD_END_DATE_QUERY:
        return state.setIn(['params', 'end_date'], action.date);
      case ADD_PRODUCT_QUERY:
        return state.updateIn(['params', 'products'], list => list.push(action.uuid));
      case REMOVE_PRODUCT_QUERY:
        return state.updateIn(['params', 'products'], list => list.filter(item => item !== action.uuid));
      case ADD_SEARCH_QUERY:
        return state.setIn(['params', 'search_query'], action.query);
      case REQUEST_SEARCH:
        return state.merge({
          loading: true
        });
      case RECEIVE_SEARCH:
        return state.merge({
          loading: false,
          data: action.payload,
          count: action.count,
          next: action.next,
          previous: action.previous,
          all: action.all,
          current:action.current
        });
      case CLEAN_SEARCH:
        return state.merge({
          data: null,
        });
      case FAIL_SEARCH:
        return state.merge({
          loading: false,
          error: action.payload
        });
      case RESET_SEARCH_QUERY:
        return initailState
      default:
        return state
    }
}
