import axios from "axios";
import { API_URL } from "config";

export const REQUEST_PRODUCTS_DESCRIPTION = 'request_products_description';
export const RECEIVE_PRODUCTS_DESCRIPTION = 'receive_products_description';
export const FAIL_PRODUCTS_DESCRIPTION = 'fail_products_description';
export const CLEAN_PRODUCTS_DESCRIPTION = 'clean_products_description';

export const fetchProductsDescription = () => async dispatch => {
  dispatch({
    type: REQUEST_PRODUCTS_DESCRIPTION
  });

  try {
    const response = await axios.get(`${API_URL}/product/description/`, {
      params: {
        language: localStorage.getItem('language')
      }
    });
    dispatch({
      type: RECEIVE_PRODUCTS_DESCRIPTION,
      payload: response.data
    });
  } catch (error) {
    dispatch({
      type: FAIL_PRODUCTS_DESCRIPTION,
      payload: error.response.data.detail
    });
  }
};

export const cleanDescription = () => dispatch => {
  dispatch({
    type: CLEAN_PRODUCTS_DESCRIPTION
  });
};
