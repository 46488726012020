import { api } from "config";
import {normalize, schema} from "normalizr";

export const REQUEST_NEWS = 'request_news';
export const RECEIVE_NEWS = 'receive_news';
export const FAIL_NEWS = 'fail_news';
export const CLEAN_NEWS = 'clean_news';

const news = new schema.Entity('news', {}, { idAttribute: 'uuid' });


export const fetchNews = (uuid) => async dispatch => {
  dispatch({
    type: REQUEST_NEWS
  });

  try {
    const response = await api.get(`/news/${uuid}/`);
    dispatch({
      type: RECEIVE_NEWS,
      payload: normalize(response.data, news)
    });
  } catch (error) {
    dispatch({
      type: FAIL_NEWS,
      payload: error.response.data.detail
    });
  }
};

export const cleanNews = () => dispatch => {
  dispatch({
    type: CLEAN_NEWS
  });
};