import { api } from "config";
import { normalize, schema } from 'normalizr';

const news = new schema.Entity('news', {}, { idAttribute: 'uuid' });

export const ADD_START_DATE_QUERY = 'add_start_date_query';
export const addStartDateQuery = date => dispatch => {
  return dispatch ({
    type: ADD_START_DATE_QUERY,
    date
  });
};

export const ADD_END_DATE_QUERY = 'add_end_date_query';
export const addEndDateQuery = date => dispatch => {
  dispatch ({
    type: ADD_END_DATE_QUERY,
    date
  });
};

export const ADD_PRODUCT_QUERY = 'add_product_query';
export const addProductQuery = uuid => dispatch => {
  dispatch ({
    type: ADD_PRODUCT_QUERY,
    uuid
  });
};

export const REMOVE_PRODUCT_QUERY = 'remove_product_query';
export const removeProductQuery = uuid => dispatch => {
  dispatch ({
    type: REMOVE_PRODUCT_QUERY,
    uuid
  });
};

export const ADD_SEARCH_QUERY = 'add_search_query';
export const addSearchQuery = query => dispatch => {
  dispatch ({
    type: ADD_SEARCH_QUERY,
    query
  });
};

export const RESET_SEARCH_QUERY = 'reset_search_query';
export const resetSearchQuery = () => dispatch => {
  dispatch ({
    type: RESET_SEARCH_QUERY
  });
};

export const REQUEST_SEARCH = 'request_search';
export const RECEIVE_SEARCH = 'receive_search';
export const FAIL_SEARCH = 'fail_search';
export const searchProduct = (params, page=1) => async dispatch => {
  dispatch({
    type: REQUEST_SEARCH
  });

  params = params + `&page=${page}`

  try {
    const response = await api.get(`/search/${params}`);
    dispatch({
      type: RECEIVE_SEARCH,
      payload: normalize(response.data.results, [news]),
      count: response.data.count,
      next: response.data.next,
      previous: response.data.previous,
      all: response.data.all,
      current: response.data.current
    });
  } catch (error) {
    dispatch({
      type: FAIL_SEARCH,
      payload: error
    });
  }
};

export const CLEAN_SEARCH = 'clean_search';
export const cleanSearchData = () => dispatch => {
  dispatch({
    type: CLEAN_SEARCH
  });
};