import axios from 'axios';
import {API_URL} from "config"

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field, SubmissionError } from 'redux-form/immutable';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { loginInputField } from './loginInputField'

import * as actions from 'state/actions/auth'


class SigninForm extends Component {

  onSubmit = formProps => {
    return axios.post(`${API_URL}/login/`, formProps)
      .then(response => {
        localStorage.setItem('token', response.data.token);
        this.props.redirect()
      })
      .catch(error => {
        if (error.response) {
          if (error.response.data.non_field_errors) {
          throw new SubmissionError({_error: error.response.data.non_field_errors, ...error.response.data})
          }
          throw new SubmissionError(error.response.data)
        } else {
          throw new SubmissionError({_error: 'API down'});
        }
      });
  };

  render() {

    const { handleSubmit, error } = this.props;

    return(
      <form onSubmit={handleSubmit(this.onSubmit)}>
        <fieldset>
          <Field
            name="username"
            type="email"
            component={loginInputField}
            label="email"
          />
        </fieldset>
        <fieldset>
          <Field
            name="password"
            type="password"
            component={loginInputField}
            label={this.context.t("password")}
          />
        </fieldset>
        {error &&
          <div>
            {error.map((error, id) =>
              <div className='field-error' key={id}>{error}</div>
            )}
          </div>
        }
        <button className='btn btn--sd-green btn--expanded'>{this.context.t("sign in")}</button>
      </form>
    )
  }
}

SigninForm.contextTypes = {
  t: PropTypes.func
};

function mapStateToProps(state) {
  return { auth: state.auth }
}

export default compose(
  connect(mapStateToProps, actions),
  reduxForm({ form: 'signin' })
)(SigninForm)
