import React, { Component } from 'react'
import classNames from "classnames";


export default class LangButton extends Component {

  render() {

    const {lang, display, setLang} = this.props;

    let languageClass = classNames({
      'btn btn--primary btn--text-only btn--ui-dark': true,
      'active': localStorage.getItem('language') === lang
    });

    return(
      <button onClick={() => setLang(lang)} className={languageClass}>{display}</button>
    )
  }
}
