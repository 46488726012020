import React, { Component } from 'react'
import { connect } from 'react-redux';
import  * as actions  from 'state/actions/clients'
import { ClientItem } from "./ClientItem";

class ClientList extends Component {

  componentWillMount() {
    this.props.fetchClients()
  }

  componentWillUnmount() {
    this.props.cleanClients()
  }

  render() {

    const { clients } = this.props;

    return(
      <div className='sd-list-item-group sd-shadow--z2'>
        {clients.get('data') && clients.getIn(['data', 'result']).map(c =>
          <ClientItem key={c} client={clients.getIn(['data', 'entities', 'clients', c])} />
        )}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return { clients: state.get('clients') }
}

export default connect(mapStateToProps, actions)(ClientList)
