import React, { Component } from "react";
import PropTypes from 'prop-types';
import DatePicker from "react-datepicker";
import queryString from 'query-string';
import classNames from 'classnames';
import moment from 'moment';
import connect from "react-redux/es/connect/connect";
import { fetchProductsOnly } from "state/actions/products";
import { 
  addStartDateQuery, 
  addEndDateQuery, 
  addProductQuery, 
  removeProductQuery, 
  resetSearchQuery 
} from "state/actions/search";

const dateToString = date => {
  if (date) {
    return `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()}`;
  } else {
    return null;
  }
}

class SearchFilter extends Component {

  constructor(props) {
    super(props);
    let date = new Date(new Date().getFullYear(),new Date().getMonth() , new Date().getDate());
    this.date_now = date;
    const start_date = props.search.getIn(['params','start_date'])
    const end_date = props.search.getIn(['params','end_date'])
    this.state = {
      today: date,
      startDate: start_date ? new Date(start_date) : null,
      endDate: end_date ? new Date(end_date) : null,
    };
  }

  componentDidMount() {
    this.props.fetchProductsOnly();
  }

  handleChangeStart = (date) => {
    this.setState({
      startDate: date
    });
    this.props.addStartDateQuery(dateToString(date));
  };

  handleChangeEnd = (date) => {
    this.setState({
      endDate: date
    });
    this.props.addEndDateQuery(dateToString(date));
  };

  handleProductCheck = (event) => {
    const target = event.target;
    const value = target.checked;
    const name = target.name;

    this.setState({
      [name]: value
    });
    
    if (value) {
      this.props.addProductQuery(name)
    } else {
      this.props.removeProductQuery(name)
    }
  };

  resetFilter() {

    this.props.resetSearchQuery();

    this.setState({
      startDate: null,
      endDate: null
    });
  }

  lastDay = () => {
    let params = this.props.search.get('params').toJS()
    params.start_date = dateToString(this.date_now)
    params.end_date = dateToString(this.date_now)
    window.location.replace(`/search/?${queryString.stringify(params)}`);
  }

  lastWeek = () => {
    let params = this.props.search.get('params').toJS()
    params.start_date = dateToString(moment().subtract(7, 'days').toDate())
    params.end_date = dateToString(this.date_now)
    window.location.replace(`/search/?${queryString.stringify(params)}`);
  }

  lastMonth = () => {
    let params = this.props.search.get('params').toJS()
    params.start_date = dateToString(moment().subtract(1, 'month').toDate())
    params.end_date = dateToString(this.date_now)
    window.location.replace(`/search/?${queryString.stringify(params)}`);
  }

  applyFilter = () => {
    const stringified = queryString.stringify(this.props.search.get('params').toJS());
    window.location.replace(`/search/?${stringified}`);
  }

  checkProductsSelect = (product) => {
    const { search } = this.props;

    let result = false;

    const selected = search.getIn(['params', 'products'])

    if (selected) {
      selected.map((select) => {
        if (product === select) {
          result = true
        }
        return null;
      });
    }

    return result;
  };

  render() {

    const { products, show } = this.props;

    let visibleClass = classNames({
      'filter-window': true,
      'visible': show
    });

    return(
      <div className='page-filter'>
        <div className={visibleClass}>
          <div className = "siadebarScrollable">
            <div>
              <label className="form-label">{this.context.t("published from:")}</label>
              <DatePicker
                selected={this.state.startDate}
                selectsStart
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                maxDate={this.state.today}
                onChange={this.handleChangeStart}
                dateFormat="dd-MM-yyyy"
              />
            </div>
            <div>
              <label className="form-label">{this.context.t("published to:")}</label>
              <DatePicker
                selected={this.state.endDate}
                selectsEnd
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                minDate={this.state.startDate || this.state.today}
                maxDate={this.state.today}
                onChange={this.handleChangeEnd}
                dateFormat="dd-MM-yyyy"
              />
            </div>
            <div className="last_range">
              <span
                className="btn btn--primary btn--small btn--hollow btn--expanded"
                onClick={() => this.lastDay()}
              >
                {this.context.t("last day")}
              </span>
            </div>
            <div className="last_range">
              <span 
                className="btn btn--primary btn--small btn--hollow btn--expanded"
                onClick={() => this.lastWeek()}
              >
                {this.context.t("last week")}
              </span>
            </div>
            <div className="last_range">
              <span 
                className="btn btn--primary btn--small btn--hollow btn--expanded"
                onClick={() => this.lastMonth()}
              >
                {this.context.t("last month")}
              </span>
            </div>
            <label className="form-label">{this.context.t("product:")}</label>
            <ul className="product-filter-list">
              {products.get('data') && products.getIn(['data', 'result']).map(product =>
                <li key={product}>
                  <label>
                    <input
                      type="checkbox"
                      checked={this.checkProductsSelect(product)}
                      name={product}
                      onChange={this.handleProductCheck}
                    />
                    { products.getIn(['data', 'entities', 'products', product, 'name']) }
                  </label>
                </li>
              )}
            </ul>
            <label className="form-label"/>
            <button
              className="btn btn--hollow btn--primary btn--expanded"
              onClick={() => this.resetFilter()}
            >
              {this.context.t("reset")}
            </button>
            <label className="form-label"/>
            <button
              className="btn btn--primary btn--expanded"
              onClick={() => this.applyFilter()}
            >
              {this.context.t("apply")}
            </button>
            <label className="form-label"/>
          </div>
        </div>
      </div>
    )
  }

}

SearchFilter.contextTypes = {
  t: PropTypes.func
};


function mapStateToProps(state) {
  return {
    search: state.get('search'),
    products: state.get('products')
  }
}

const mapDispatchToProps = {
  addStartDateQuery,
  addEndDateQuery,
  addProductQuery,
  removeProductQuery,
  resetSearchQuery,
  fetchProductsOnly
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchFilter)
