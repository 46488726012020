import React from 'react';
import PropTypes from 'prop-types';
import { DateFormat } from 'common/date';
import { Link } from 'react-router-dom';
import classNames from "classnames";

export const NewsItem = ({ news, openNewsModal }, context) => {

  let headlineClass = classNames({
    'sd-overflow-ellipsis': true,
    'sd-list-item--element-grow': true,
    'sd-list-item__headline-bold': news.get('priority') && news.get('priority') < 3,
    'sd-list-item__headline-red': news.get('priority') && news.get('priority') < 2
  });

  return (
    <div className='sd-list-item' onClick={() => openNewsModal(news.get('uuid'))}>
      <div className="sd-list-item__column"><i className="icon-text" /></div>
      <div className="sd-list-item__column sd-list-item__column--grow sd-list-item__column--no-border">
        <div className="sd-list-item__row">
          <span className={headlineClass}>{ news.get('headline') }</span>
          <Link to={'/news/' + news.get('uuid')}><i className="icon-link"/></Link>
          <DateFormat date={news.get('version_created')}/>
        </div>
        { news.get('product') &&
          <div className="sd-list-item__row">
            <span className="sd-list-item__text-label">{context.t("product:")}</span>
            <span className="sd-overflow-ellipsis sd-list-item__text-strong">{ news.get('product').map(p => p.get('name')) }</span>
          </div>
        }
      </div>
    </div>
  )
};

NewsItem.contextTypes = {
  t: PropTypes.func
};