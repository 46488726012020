import React, { Component } from 'react';
import { Route, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {Helmet} from "react-helmet";
import { connect } from 'react-redux';

import * as actions from 'state/actions/auth';

import ProfileButton from 'main/ProfileButton';
import ProfileMenu from 'main/ProfileMenu';

import { ProductListPage, ProductDetailPage } from 'pages/product';
import { NewsDetailPage } from 'pages/news';
import { SearchListPage } from 'pages/search';
import { AdminPage, ClientPage } from 'pages/admin';
import ReactTooltip from "react-tooltip";


class Main extends Component {

  componentDidMount() {
    this.props.fetchAuthUser()
  }

  render() {

    const { match, auth } = this.props;

    return(
      <div>
        <Helmet>
          <title>{this.context.t("UKRINFORM")}</title>
        </Helmet>
        <ReactTooltip effect="solid" className='sd-tooltip' />
        <div className='sd-top-menu'>
          <p className='sd-top-menu__header'>
            <Link to='/' className='logo'>
              <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="180px" height="26px" viewBox="0 0 250.403 26" enableBackground="new 0 0 250.403 26" xmlSpace="preserve">
                <polygon fill="#1eb06c" points="230.74,10.634 235.132,16.469 239.448,16.469 243.928,10.645 243.928,25.332 251.036,25.332    251.036,0.816 243.846,0.816 237.289,10.733 230.821,0.816 223.632,0.816 223.632,25.332 230.74,25.332  "/>
                <polygon fill="#1eb06c" points="97.445,25.328 97.455,25.318 97.445,0.671 90.206,0.682 90.216,25.328  "/>
                <polygon fill="#1eb06c" points="112.339,25.318 112.339,17.246 122.249,17.246 122.259,25.328 129.488,25.328 129.498,25.318    129.488,0.671 122.249,0.682 122.249,10.759 112.339,10.759 112.329,0.671 105.09,0.682 105.1,25.328  "/>
                <path fill="#1eb06c" d="M180.521,25.947c7.139,0,12.947-5.808,12.947-12.947S187.66,0.053,180.521,0.053S167.573,5.861,167.573,13   C167.574,20.139,173.382,25.947,180.521,25.947z M180.521,6.934h0.003c3.338,0.001,6.053,2.718,6.053,6.056   c0,2.712-1.828,5.111-4.438,5.831c-0.533,0.163-1.077,0.246-1.618,0.246c-0.538,0-1.081-0.082-1.606-0.243   c-2.62-0.72-4.45-3.119-4.45-5.834C174.465,9.65,177.182,6.934,180.521,6.934z"/>
                <path fill="#1eb06c" d="M34.627,25.328l7.178-0.011l0.018-8.436h3.411l3.773,5.459c1.305,1.889,3.424,3.017,5.666,3.017h4.197   l-0.002-0.002l-0.006-0.017l-1.043-6.451c-0.005-0.006-0.013-0.011-0.019-0.011l-0.645-0.004c-1.421,0-1.959-0.064-3-1.391   l-0.007,0.026l-2.363-3.233l-0.855-1.183L57.602,0.7l-0.005-0.022l0.004-0.006l-7.504-0.006l-4.926,9.92l-3.436-0.036l0.06-9.879   L34.572,0.67c-0.003,0.002-0.016,0.017-0.016,0.048v24.483C34.556,25.328,34.581,25.328,34.627,25.328z"/>
                <path fill="#1eb06c" d="M204.867,25.403l0.01-0.01v-5.321l5.321-0.576v-0.014l0.664-0.063c4.817-0.469,8.449-4.472,8.449-9.311   c0-5.162-4.199-9.361-9.361-9.361h-4.914l-7.33,0.01l0.01,24.647L204.867,25.403L204.867,25.403z M204.877,7.092l4.659-0.023h0.001   c1.377,0,2.985,0.79,2.985,3.015c0,1.316-0.593,2.041-1.094,2.42c-0.477,0.35-1.008,0.444-1.509,0.51l-5.042,0.715V7.092   L204.877,7.092z"/>
                <path fill="#1eb06c" d="M70.962,25.403l0.01-0.01v-5.321l5.321-0.576v-0.014l0.663-0.063c4.817-0.469,8.449-4.472,8.449-9.311   c0-5.162-4.199-9.361-9.361-9.361h-4.84l-7.403,0.01l0.01,24.647L70.962,25.403L70.962,25.403z M70.972,7.092l4.659-0.023h0.001   c1.377,0,2.985,0.79,2.985,3.015c0,1.316-0.594,2.041-1.094,2.42c-0.477,0.35-1.008,0.444-1.509,0.51l-5.042,0.715   C70.972,13.729,70.972,7.092,70.972,7.092z"/>
                <path fill="#1eb06c" d="M138.306,19.399c3.098,2.202,6.597,2.314,7.266,2.314c0.058,0,0.09-0.001,0.092-0.001l0.11,0.001   l0.135,0.147l-0.005,0.312v1.073l0.01,2.047h7.264l0.01-0.01v-3.106l-0.004-0.223l0.002-0.097l0.135-0.147l0.106,0.003   c0.002,0,0.092,0.001,0.092,0.001c0.67,0,4.168-0.112,7.259-2.309c2.612-2.014,4.11-5.046,4.11-8.319   c0-3.504-1.746-6.772-4.666-8.738c-2.017-1.275-4.336-1.693-5.535-1.829c-0.584-0.054-1.274-0.102-1.984-0.138h-6.305   c-0.722,0.036-1.412,0.084-1.991,0.138c-1.203,0.136-3.523,0.555-5.534,1.826c-2.925,1.97-4.671,5.238-4.671,8.742   C134.203,14.358,135.702,17.391,138.306,19.399z M153.102,6.75h1.25c2.346,0,4.115,1.831,4.115,4.259   c0,0.028-0.001,0.056-0.003,0.085v0.041c0,0.025-0.002,0.053-0.003,0.081l-0.004,0.085c-0.001,0.033-0.002,0.065-0.005,0.095   c-0.015,0.232-0.052,0.475-0.114,0.738c-0.032,0.135-0.065,0.25-0.107,0.373c-0.041,0.12-0.085,0.248-0.149,0.386   c-0.008,0.015-0.016,0.029-0.024,0.038c-0.483,1.036-1.641,2.316-4.41,2.534l-0.097,0.02h-0.449v-0.401H153.1v-0.261l-0.008,0.002   L153.102,6.75z M144.742,6.75h1.25l0.01,7.79l-0.008,0.282v0.261h-0.001v0.401h-0.448l-0.097-0.02   c-2.77-0.219-3.927-1.498-4.409-2.53c-0.009-0.013-0.017-0.027-0.03-0.053c-0.059-0.131-0.104-0.258-0.149-0.389   c-0.037-0.11-0.07-0.225-0.107-0.378c-0.058-0.247-0.095-0.489-0.11-0.717c-0.002-0.032-0.004-0.064-0.005-0.095l-0.004-0.09   c-0.002-0.027-0.003-0.054-0.003-0.079l-0.001-0.041c-0.001-0.028-0.003-0.057-0.003-0.085   C140.627,8.581,142.396,6.75,144.742,6.75z"/>
                <path fill="#1eb06c" d="M23.129,0.697l-9.787,16.648c-0.839,1.401-1.352,1.648-2.476,1.678l-2.008-0.001L7.38,25.364l0.022,0.022   h5.938c2.236,0,4.24-1.123,5.36-3.004l12.898-21.66L31.578,0.7L23.129,0.697z"/>
                <path fill="#1eb06c" d="M3.839,8.291L9.818,8.29l4.331,3.553c0.064,0.059,0.148,0.096,0.242,0.096c0.197,0,0.356-0.159,0.356-0.356   c0-0.008-0.002-0.015-0.002-0.023c0.001,0,0.004-0.001,0.005-0.001l-0.01-0.045c0-0.002-0.001-0.004-0.001-0.006l-1.621-7.634   l-0.004-0.026c-0.009-0.058-0.018-0.116-0.03-0.177l-0.015-0.067c-0.393-1.733-1.967-2.991-3.741-2.991H3.839   C1.722,0.613,0,2.335,0,4.452S1.722,8.291,3.839,8.291z"/>
              </svg>
            </Link>
          </p>
          {auth.get('user') && auth.getIn(['user', 'rsskey']) &&
            <div className='sd-top-menu__button'>
              <Link to={`/rss/?token=${auth.getIn(['user', 'rsskey'])}`} target="_blank" className="btn btn--warning btn--icon-only btn--hollow">
                <i className='icon-broadcast'/>
              </Link>
            </div>
          }
          <ProfileButton />
          <ProfileMenu />
        </div>
        <div className='container-fluid sd-container'>
          <Route exact path={ `${match.url}` } component={ ProductListPage } />
          <Route exact path={ `${match.url}product/:uuid/` } component={ ProductDetailPage } />
          <Route exact path={ `${match.url}news/:uuid/` } component={ NewsDetailPage } />
          <Route exact path={ `${match.url}search/` } component={ SearchListPage } />
          <Route exact path={ `${match.url}admin/` } component={ AdminPage } />
          <Route exact path={ `${match.url}admin/:uuid/` } component={ ClientPage } />
        </div>
      </div>
    )
  }
}

Main.contextTypes = {
  t: PropTypes.func
};

function mapStateToProps(state) {
  return { auth: state.get('auth') }
}

export default connect(mapStateToProps, actions)(Main)