import React, { Component } from "react";
import PropTypes from 'prop-types';
import connect from "react-redux/es/connect/connect";
import queryString from 'query-string';
import SearchInput from 'pages/search/SearchInput';
import SearchFilter from 'pages/search/SearchFilter';
import PanelInfo from 'common/PanelInfo';
import Spiner from 'common/Spiner';
import { NewsList } from "pages/product/NewsList";
import ModalNews from 'pages/product/modal';
import { Paginator } from 'common/Paginator';

import { 
  searchProduct,
  addStartDateQuery, 
  addEndDateQuery, 
  addProductQuery,
  addSearchQuery,
  cleanSearchData,
  resetSearchQuery 
} from "state/actions/search";

import { toggleSubnavSearch, resetApp } from "state/actions/app";


// TODO: a lot of duplicated code for modal window... need to change architechture... maybe move logic to app layer
class SearchListPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      modal: false
    };
    this.params = queryString.parse(props.location.search)
    if (this.params.start_date) {
      props.addStartDateQuery(this.params.start_date)
    }
    if (this.params.end_date) {
      props.addEndDateQuery(this.params.end_date)
    }

    if (this.params.products) {
      if (Array.isArray(this.params.products)) {
        this.params.products.forEach(uuid => (
          props.addProductQuery(uuid)
        ))
      } else {
        props.addProductQuery(this.params.products)
      }
      
    }
    if (this.params.search_query) {
      props.addSearchQuery(this.params.search_query)
    }
    props.toggleSubnavSearch();
  }

  componentDidMount() {
    this.props.searchProduct(this.props.location.search)
  }

  paginatorClick = (page) => {
    if (page) {
      this.props.cleanSearchData();
      this.props.searchProduct(this.props.location.search, page);
    }
  }

  componentWillUnmount() {
    this.props.resetApp();
    this.props.resetSearchQuery()
  }

  openModal = (news) => {
    this.setState({
      modal: this.props.search.getIn(['data', 'entities', 'news', news])
    });
  };

  closeModal = () => this.setState({modal: false});

  render() {

    const { search, app } = this.props;

    const panel = (
      <PanelInfo
        heading={this.context.t("Your search did not match any news")}
        description={this.context.t("Try more general keywords or change dates")}
      />
    )

    return(
      <div className='sd-container__with-subnav'>
        <div className="subnav">
          <SearchInput />
        </div>
        <SearchFilter  show = {app.get('show_subnav_search')} />
        <div className="sd-list-item-group filter-active">
          {search.get('loading') &&
            <Spiner />
          }

          { search.get('data') && search.getIn(['data', 'result']).isEmpty() &&
            panel
          }
          { search.get('data') === null && !search.get('loading') &&
            panel
          }
          {search.get('data') && 
            <NewsList
              news={search.getIn(['data', 'result'])}
              newsList={search.getIn(['data', 'entities', 'news'])}
              openNewsModal={this.openModal}
            />
          }
          {search.get('data') !== null && 
            <Paginator
              previous={search.get('previous')}
              next={search.get('next')}
              current={search.get('current')}
              all={search.get('all')}
              paginatorClick={this.paginatorClick}
            />
          }
        </div>
        {this.state.modal &&
          <ModalNews
            news={this.state.modal}
            closeModal={this.closeModal}
          />
        }
      </div>
    )
  }
}

SearchListPage.contextTypes = {
  t: PropTypes.func
};

function mapStateToProps(state) {
  return {
    search: state.get('search'),
    app: state.get('app')
  }
}

const mapDispatchToProps = {
  searchProduct,
  addStartDateQuery,
  addEndDateQuery,
  addProductQuery,
  addSearchQuery,
  resetSearchQuery,
  cleanSearchData,
  toggleSubnavSearch,
  resetApp
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchListPage)
