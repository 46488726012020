import {
  combineReducers
} from 'redux-immutable';

import { i18nState } from "redux-i18n/immutable";
import { reducer as formReducer } from 'redux-form/immutable';

import { auth } from 'state/reducers/auth';
import { product } from 'state/reducers/product';
import { products } from 'state/reducers/products';
import { clients } from 'state/reducers/clients';
import { client } from 'state/reducers/client';
import { products_description } from 'state/reducers/products_description';
import { app } from 'state/reducers/app';
import { news } from 'state/reducers/news';
import { search } from 'state/reducers/search';

const rootReducer = combineReducers({
  i18nState,
  form: formReducer,
  app,
  search,
  auth,
  products,
  product,
  news,
  clients,
  client,
  products_description
});

export default rootReducer;
