import { Map } from 'immutable'

import {
  REQUEST_NEWS,
  RECEIVE_NEWS,
  FAIL_NEWS,
  CLEAN_NEWS
} from 'state/actions/news';

const initailState = new Map({
  loading: false
});

export function news(state=initailState, action) {
  switch (action.type) {
    case REQUEST_NEWS:
      return state.merge({
        loading: true
      });
    case RECEIVE_NEWS:
      return state.merge({
        loading: false,
        data: action.payload
      });
    case FAIL_NEWS:
      return state.merge({
        loading: false,
        error: action.payload
      });
    case CLEAN_NEWS:
      return initailState;
    default:
      return state
  }
}
