import { Map, fromJS } from 'immutable'

import {
  REQUEST_PRODUCTS,
  RECEIVE_PRODUCTS,
  FAIL_PRODUCTS,
  ADD_NEWS_TO_PRODUCTS,
  UPDATE_NEWS_IN_PRODUCTS,
  REMOVE_NEWS_FROM_PRODUCTS,
  CLEAN_PRODUCTS
} from 'state/actions/products';

const initailState = new Map({
  loading: false,
  modal: false
});

export function products(state=initailState, action) {
  switch (action.type) {
    case REQUEST_PRODUCTS:
      return state.merge({
        loading: true
      });
    case RECEIVE_PRODUCTS:
      return state.merge({
        loading: false,
        data: action.payload
      });
    case ADD_NEWS_TO_PRODUCTS:
      return state.setIn(['data', 'entities', 'news', action.payload.news.uuid], Map(fromJS(action.payload.news)))
      .updateIn(['data', 'entities', 'products', action.payload.product, 'news'], list => list.pop().insert(0, action.payload.news.uuid))
    case UPDATE_NEWS_IN_PRODUCTS:
      return state.updateIn(['data', 'entities', 'news', action.payload.news.uuid], news => Map(fromJS(action.payload.news)))
    case REMOVE_NEWS_FROM_PRODUCTS:
      return state.updateIn(['data', 'entities', 'products', action.payload.product, 'news'], list => list.filter(news => news !== action.payload.news))
    case FAIL_PRODUCTS:
      return Object.assign({}, state, {
        loading: false,
        error: action.payload
      });
    case CLEAN_PRODUCTS:
      return initailState;
    default:
      return state
  }
}
