import { api } from "config";
import { normalize, schema } from 'normalizr';

export const REQUEST_PRODUCTS = 'request_products';
export const RECEIVE_PRODUCTS = 'receive_products';
export const FAIL_PRODUCTS = 'fail_products';
export const CLEAN_PRODUCTS = 'clean_products';

const news = new schema.Entity('news', {}, { idAttribute: 'uuid' });
const products = new schema.Entity(
  'products',
  {news: [news]},
  { idAttribute: 'uuid' });

export const fetchProducts = (query='') => async dispatch => {
  dispatch({
    type: REQUEST_PRODUCTS
  });

  let params = {
    with_news: 1
  };

  if (query) {
    params.q = query;
  }

  try {
    const response = await api.get(`/product/`, {
      params: params
    });
    dispatch({
      type: RECEIVE_PRODUCTS,
      payload: normalize(response.data, [products])
    });
  } catch (error) {
    dispatch({
      type: FAIL_PRODUCTS,
      payload: error.response.data.detail
    });
  }
};

export const fetchProductsOnly = () => async dispatch => {
  dispatch({
    type: REQUEST_PRODUCTS
  });

  try {
    const response = await api.get(`/product/`);
    dispatch({
      type: RECEIVE_PRODUCTS,
      payload: normalize(response.data, [products])
    });
  } catch (error) {
    dispatch({
      type: FAIL_PRODUCTS,
      payload: error.response.data.detail
    });
  }
};

export const cleanProducts = () => dispatch => {
  dispatch({
    type: CLEAN_PRODUCTS
  });
};

export const ADD_NEWS_TO_PRODUCTS = 'add_news_to_products';
export const addProducts = (data) => dispatch => {
  dispatch({
    type: ADD_NEWS_TO_PRODUCTS,
    payload: data
  });
};

export const UPDATE_NEWS_IN_PRODUCTS = 'update_news_in_products';
export const updateProducts = (data) => dispatch => {
  dispatch({
    type: UPDATE_NEWS_IN_PRODUCTS,
    payload: data
  });
};

export const REMOVE_NEWS_FROM_PRODUCTS = 'remove_news_from_products';
export const removeProducts = (data) => dispatch => {
  dispatch({
    type: REMOVE_NEWS_FROM_PRODUCTS,
    payload: data
  });
};