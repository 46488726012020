export const TOGGLE_USER_MENU = 'toggle_user_menu';

export const toggleUserMenu = () => ({
    type: TOGGLE_USER_MENU
});

export const TOGGLE_SUBNAV_SEARCH = 'toggle_subnav_search';
export const toggleSubnavSearch = () => ({
    type: TOGGLE_SUBNAV_SEARCH
});

export const ADD_SEARCH_QUERY = 'add_search_query';
export const addSearchQuery = (query) => ({
    type: ADD_SEARCH_QUERY,
    query
});

export const TOGGLE_NEWS_FILTER = 'toggle_news_filter';
export const toggleNewsFilter = () => ({
    type: TOGGLE_NEWS_FILTER
});

export const RESET_APP = 'reset_app';
export const resetApp = () => ({
    type: RESET_APP
});