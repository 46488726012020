import React, { Component } from "react";
import connect from "react-redux/es/connect/connect";
import * as actions from "state/actions/news";
import { Loader } from 'common/loader';
import { DateFormat } from 'common/date'


class NewsDetailPage extends Component {

  componentWillMount() {
    this.props.fetchNews(this.props.match.params.uuid)
  }

  render() {

    const news = this.props.news.getIn(['data', 'entities', 'news', this.props.match.params.uuid]);

    if (!news) {
      return (<Loader/>)
    } else {
      return (
        <div className='sd-container__with-subnav'>
          <div className="subnav">
            <h3 className='subnav__page-title'>{news.get('headline')}</h3>
          </div>
          <DateFormat date={news.get('version_created')}/>
          {news.get('attachments') && !!news.get('attachments').size &&
            <div>
              <i className="icon-attachment-large"/>&nbsp;
              {news.get('attachments').map(n =>
                  <a key={n.get('superdesk_guid')} target="_blank" rel="noopener noreferrer" style={{marginRight: '20px'}} href={ n.get('url') }>{ n.get('name') }</a>
              )}
            </div>
          }
          <div className='text-wrap' dangerouslySetInnerHTML={{__html: news.get('body_html_modify')}} />
          {news.get('byline') && <p dangerouslySetInnerHTML={{__html: news.get('byline')}} />}
        </div>
      )
    }
  }
}

function mapStateToProps(state) {
  return { news: state.get('news') }
}

export default connect(mapStateToProps, actions)(NewsDetailPage)
