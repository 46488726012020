import { Map } from 'immutable'

import {
  REQUEST_PRODUCTS_DESCRIPTION,
  RECEIVE_PRODUCTS_DESCRIPTION,
  FAIL_PRODUCTS_DESCRIPTION,
  CLEAN_PRODUCTS_DESCRIPTION
} from 'state/actions/products_description';


const initailState = new Map({
  loading: false
});

export function products_description(state=initailState, action) {
  switch (action.type) {
    case REQUEST_PRODUCTS_DESCRIPTION:
      return state.merge({
        loading: true
      });
    case RECEIVE_PRODUCTS_DESCRIPTION:
      return state.merge({
        loading: false,
        data: action.payload
      });
    case FAIL_PRODUCTS_DESCRIPTION:
      return Object.assign({}, state, {
        loading: false,
        error: action.payload
      });
    case CLEAN_PRODUCTS_DESCRIPTION:
      return initailState;
    default:
      return state
  }
}
