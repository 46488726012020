import React from 'react'

export const DateFormat = ({ date }) => (
  <time>
    {new Intl.DateTimeFormat('uk', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit'
    }).format(Date.parse(date))}
  </time>
);
