import React, { Component } from "react";
import PropTypes from 'prop-types';
import queryString from 'query-string';
import connect from "react-redux/es/connect/connect";
import { addSearchQuery } from "state/actions/search";
import { toggleSubnavSearch } from 'state/actions/app';
import classNames from 'classnames';


class SearchInput extends Component {

  handleChange = (event) => {
    this.props.addSearchQuery(event.target.value)
  };

  search = () => {
    const stringified = queryString.stringify(this.props.search.get('params').toJS());
    window.location.replace(`/search/?${stringified}`);
  }

  handleEnter = (event) => {
    if (event.key === 'Enter') {
      this.search()
    }
  };

  toggleSearch = () => {
    if (this.props.toggable) {
      this.props.toggleSubnavSearch();
    }
  }

  render() {

    const { app, search } = this.props;

    let handlerClass = classNames({
      'search-handler': true,
      'visible': app.get('show_subnav_search')
    });

    let inputClass = classNames({
      'visible': app.get('show_subnav_search')
    });

    let buttonClass = classNames({
      'search-close': true,
      'visible': app.get('show_subnav_search')
    });

    return(
      <div className={handlerClass}>
        <label htmlFor="search-input" className="trigger-icon" onClick={() => this.toggleSearch()}>
          <i className="icon-search" />
        </label>
        <input
          type="text"
          placeholder={this.context.t("search")}
          value={this.props.search.getIn(['params', 'search_query'])}
          onChange={this.handleChange}
          onKeyPress={this.handleEnter}
          className={inputClass}
        />
        <button className={buttonClass} onClick={() => this.search()}><i className="icon-chevron-right-thin" /></button>
        { search.get('data') && !search.getIn(['data', 'result']).isEmpty() &&
          <div className='counter'>{this.context.t("Found:")} {search.getIn(['count'])}</div>
        }
      </div>
    )
  }
}

SearchInput.contextTypes = {
  t: PropTypes.func
};

function mapStateToProps(state) {
  return {
    search: state.get('search'),
    app: state.get('app')
  }
}

const mapDispatchToProps = {
  addSearchQuery,
  toggleSubnavSearch
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchInput)
