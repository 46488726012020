import { Map } from "immutable";
import { REQUEST_AUTH, RECEIVE_AUTH, FAIL_AUTH } from 'state/actions/auth'


const initailState = new Map({
  loading: false
});

export function auth(state=initailState, action) {
  switch (action.type) {
    case REQUEST_AUTH:
      return state.merge({
        loading: true
      });
    case RECEIVE_AUTH:
      return state.merge({
        loading: false,
        user: action.payload
      });
    case FAIL_AUTH:
      return state.merge({
        isFetching: false,
        errors: action.payload
      });
    default:
      return state
  }
}
