import { Map } from "immutable";

import {
  REQUEST_CLIENT,
  RECEIVE_CLIENT
} from 'state/actions/client'

const initailState = new Map({
  loading: false
});

export function client(state=initailState, action) {
  switch (action.type) {
    case REQUEST_CLIENT:
      return state.merge({
        loading: true
      });
    case RECEIVE_CLIENT:
      return state.merge({
        loading: false,
        data: action.payload
      });
    default:
      return state;
  }
}
