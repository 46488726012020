import React, { Component } from 'react';
import { reduxForm, Field } from 'redux-form/immutable';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { InputField } from "common/InputField";
import { HiddenInputField } from "common/HiddenInputField";

import * as actions from 'state/actions/client'

class ClientForm extends Component {

  onSubmit = formProps => {
    console.log(formProps.toJS());
    this.props.updateClient(formProps.toJS())
  };

  render() {

    const { handleSubmit } = this.props;

    return(
      <form onSubmit={handleSubmit(this.onSubmit)}>
        <Field
          name="uuid"
          component={HiddenInputField}
        />
        <Field
          name="name"
          required={true}
          type="text"
          component={InputField}
          label="Name"
        />
        <Field
          name="description"
          type="text"
          component={InputField}
          label="description"
        />
        <Field
          name="address"
          type="text"
          component={InputField}
          label="address"
        />
        <Field
          name="phones"
          type="text"
          component={InputField}
          label="phones"
        />
        <Field
          name="contact"
          type="text"
          component={InputField}
          label="contact"
        />
        <button type='submit' className="btn btn--large btn--expanded btn--sd-green">Save</button>
      </form>
    )
  }
}

export default compose(
  reduxForm({
    form: 'client',
    enableReinitialize: true
  }),
  connect(null, actions),
)(ClientForm)
