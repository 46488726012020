import {api} from "config";
import {normalize, schema} from "normalizr";

const users = new schema.Entity(
  'users',
  {},
  { idAttribute: 'uuid' });

const products = new schema.Entity(
  'products',
  {},
  { idAttribute: 'uuid' });

const clients = new schema.Entity(
  'clients',
  {users: [users], products: [products],},
  { idAttribute: 'uuid' });


export const REQUEST_CLIENT = 'request_client';
export const RECEIVE_CLIENT = 'receive_client';
export const FAIL_CLIENT = 'fail_client';

export const fetchClient = (uuid) => async dispatch => {
  dispatch({
    type: REQUEST_CLIENT
  });

  try {
    const response = await api.get(`/client/${uuid}/`);
    dispatch({
      type: RECEIVE_CLIENT,
      payload: normalize(response.data, clients)
    });
  } catch (error) {
    dispatch({
      type: FAIL_CLIENT,
      payload: error.response.data
    });
  }
};

export const updateClient = (formProps) => async dispatch => {
  dispatch({
    type: REQUEST_CLIENT
  });

  try {
    const response = await api.put(`/client/${formProps.uuid}/`, formProps);
    dispatch({
      type: RECEIVE_CLIENT,
      payload: normalize(response.data, clients)
    });
  } catch (error) {
    dispatch({
      type: FAIL_CLIENT,
      payload: error.response.data
    });
  }
};