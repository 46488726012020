import React, {Component} from "react";
import ModalNews from './modal'
import connect from "react-redux/es/connect/connect";
import { fetchProduct, cleanProduct, addProduct, removeProduct, updateProduct } from "state/actions/product";
import { fetchProductsOnly } from "state/actions/products";
import { addProductQuery, resetSearchQuery } from "state/actions/search";
import { resetApp } from "state/actions/app";
import Spiner from 'common/Spiner';
import { NewsList } from "./NewsList";
import { Websocket } from "tools/websocket";
import SearchInput from 'pages/search/SearchInput';
import SearchFilter from 'pages/search/SearchFilter';
import ProductSelect from "./ProductSelect";
import { Paginator } from 'common/Paginator';
import classNames from "classnames";

class ProductDetailPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      modal: false
    };
    this.socket = new Websocket('/news/', news => this.websocketHandler(news))
  }

  websocketHandler = (news) => {
    if (news.type === 'new' && news.created) {
      this.props.addProduct(news)
    } if (news.type === 'new' && !news.created) {
      this.props.updateProduct(news)
    } else if (news.type === 'canceled') {
      this.props.removeProduct(news)
    }
  }

  componentDidMount() {
    this.props.fetchProduct(this.props.match.params.uuid);
    this.props.addProductQuery(this.props.match.params.uuid);
    this.props.fetchProductsOnly();
  }

  componentWillUnmount() {
    this.props.cleanProduct();
    this.props.resetSearchQuery();
    this.props.resetApp();
    this.socket.close();
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.uuid !== prevProps.match.params.uuid) {
      this.props.fetchProduct(this.props.match.params.uuid);
      this.props.resetSearchQuery();
      this.props.resetApp();
      this.props.addProductQuery(this.props.match.params.uuid);
    }
  }

  paginatorClick = (page) => {
    if (page) {
      this.props.cleanProduct();
      this.props.fetchProduct(this.props.match.params.uuid, page);
    }
  }

  openModal = (news) => {
    this.setState({
      modal: this.props.product.getIn(['data', 'entities', 'news', news])
    });
  };

  closeModal = () => this.setState({modal: false});

  render() {
    const product = this.props.product.getIn(['data', 'entities', 'products', this.props.match.params.uuid]);
    const { app, products } = this.props;
    let clean_products = null;
    if (products.getIn(['data'])) {
      clean_products = products.updateIn(['data', 'result'], list => list.filterNot(p => p === this.props.match.params.uuid))
    }

    let paginatorClass = classNames({
      'filter-active': app.get('show_subnav_search')
    });

    if (!product) {
      return(<Spiner />)
    } else {
      return(
        <div className='sd-container__with-subnav'>
          <div className="subnav">
            <SearchInput toggable = {true} />
            <ProductSelect
              current={product.get('name')}
              list={clean_products}
            />
          </div>
          <SearchFilter show = {app.get('show_subnav_search')}/>
          <NewsList
            news={product.get('news')}
            newsList={this.props.product.getIn(['data', 'entities', 'news'])}
            openNewsModal={this.openModal}
            showFilter = {app.get('show_subnav_search')}
          />
          <div className={paginatorClass}>
            <Paginator
              previous={product.get('previous')}
              next={product.get('next')}
              current={product.get('current')}
              all={product.get('all')}
              paginatorClick={this.paginatorClick}
            />
          </div>
          {this.state.modal &&
            <ModalNews
              news={this.state.modal}
              closeModal={this.closeModal}
            />
          }
        </div>
      )
    }
  }
}

function mapStateToProps(state) {
  return {
    product: state.get('product'),
    products: state.get('products'),
    app: state.get('app')
  }
}

const mapDispatchToProps = {
  addProductQuery,
  resetSearchQuery,
  fetchProduct,
  addProduct,
  removeProduct,
  updateProduct,
  cleanProduct,
  fetchProductsOnly,
  resetApp
}


export default connect(mapStateToProps, mapDispatchToProps)(ProductDetailPage)
