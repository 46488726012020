import React from 'react'
import ClientList from './ClientList.js'


export const AdminPage = () => (
  <div className='sd-container__with-subnav'>
    <div className="subnav">
      <h3 className='subnav__page-title'>Clients</h3>
      <div className="subnav__button-stack--square-buttons">
        <button className="navbtn dropdown sd-create-btn">
          <i className="icon-plus-large" />
          <span className="circle" />
        </button>
      </div>
    </div>
    <ClientList />
  </div>
);
