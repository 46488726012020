import { Map } from "immutable";

import {
  REQUEST_CLIENTS,
  RECEIVE_CLIENTS,
  CLEAN_CLIENTS,
} from 'state/actions/clients'

const initailState = new Map({
  loading: false
});

export function clients(state=initailState, action) {
  switch (action.type) {
    case REQUEST_CLIENTS:
      return state.merge({
        loading: true
      });
    case RECEIVE_CLIENTS:
      return state.merge({
        loading: false,
        data: action.payload
      });
    case CLEAN_CLIENTS:
      return initailState;
    default:
      return state;
  }
}
