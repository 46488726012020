import React, { Component } from "react";
import connect from "react-redux/es/connect/connect";
import { toggleUserMenu } from 'state/actions/app'

class ProfileButton extends Component {
  render() {

    const { auth, toggleMenu } = this.props;

    return(
      <button onClick={() => toggleMenu()} className="sd-top-menu__current-user">
        <figure className="avatar initials">
          <span>{auth.getIn(['user', 'display_initial'])}</span>
        </figure>
      </button>
    )
  }
}

function mapStateToProps(state) {
  return {
    auth: state.get('auth')
  }
}

const mapDispatchToProps = dispatch => ({
  toggleMenu: () => dispatch(toggleUserMenu())
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileButton)
