import { api } from "config";
import { normalize, schema } from 'normalizr';

export const REQUEST_PRODUCT = 'request_product';
export const RECEIVE_PRODUCT = 'receive_product';
export const FAIL_PRODUCT = 'fail_product';
export const CLEAN_PRODUCT = 'clean_product';

const news = new schema.Entity('news', {}, { idAttribute: 'uuid' });
const products = new schema.Entity(
  'products',
  {news: [news]},
  { idAttribute: 'uuid' });


export const fetchProduct = (uuid, page=1) => async dispatch => {
  dispatch({
    type: REQUEST_PRODUCT
  });

  let params = {
    with_news: 1,
    page
  };

  try {
    const response = await api.get(`/product/${uuid}/`, {
      params: params
    });
    dispatch({
      type: RECEIVE_PRODUCT,
      payload: normalize(response.data, products)
    });
  } catch (error) {
    dispatch({
      type: FAIL_PRODUCT,
      payload: error.response.data.detail
    });
  }
};

export const cleanProduct = () => dispatch => {
  dispatch({
    type: CLEAN_PRODUCT
  });
};

export const ADD_NEWS_TO_PRODUCT = 'add_news_to_product';
export const addProduct = (data) => dispatch => {
  dispatch({
    type: ADD_NEWS_TO_PRODUCT,
    payload: data
  });
};

export const UPDATE_NEWS_IN_PRODUCT = 'update_news_in_product';
export const updateProduct = (data) => dispatch => {
  dispatch({
    type: UPDATE_NEWS_IN_PRODUCT,
    payload: data
  });
};

export const REMOVE_NEWS_FROM_PRODUCT = 'remove_news_from_product';
export const removeProduct = (data) => dispatch => {
  dispatch({
    type: REMOVE_NEWS_FROM_PRODUCT,
    payload: data
  });
};
