import React from 'react'
import ClientProductsItem from './ClientProductsItem'

export const ClientProductsList = ({ products }) => (
  <div className='sd-list-item-group sd-shadow--z2'>
    {products.map(n =>
      <ClientProductsItem key={n} product={n} />
    )}
  </div>
);
