import React from 'react'
import ClienUsersItem from './ClientUsersItem'

export const ClientUsersList = ({ users }) => (
  <div className='sd-list-item-group sd-shadow--z2'>
    {users.map(n =>
      <ClienUsersItem key={n} user={n} />
    )}
  </div>
);
