import React, { Component } from 'react'
import connect from "react-redux/es/connect/connect";

class ClientProductsItem extends Component {
  render() {

    const { product, client} = this.props;
    return(
      <div className='sd-list-item'>
        <div className="sd-list-item__column"><i className="icon-text"/></div>
        <div className="sd-list-item__column sd-list-item__column--grow sd-list-item__column--no-border">
          <div className="sd-list-item__row">
            <span className="sd-overflow-ellipsis sd-list-item--element-grow">{client.getIn(['data', 'entities', 'products', product, 'name'])}</span>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    client: state.get('client')
  }
}

export default connect(mapStateToProps)(ClientProductsItem)
