import { api } from "config";

export const REQUEST_AUTH = 'request_auth';
export const RECEIVE_AUTH = 'receive_auth';
export const FAIL_AUTH = 'fail_auth';

export const fetchAuthUser = () => async dispatch => {
  dispatch({
    type: REQUEST_AUTH
  });

  try {
    const response = await api.get('/profile/auth/');
    dispatch({
      type: RECEIVE_AUTH,
      payload: response.data
    });
  } catch (error) {
    dispatch({
      type: FAIL_AUTH,
      payload: error.response.data
    });
  }
};
