import React, { Component } from 'react';
import { Redirect } from 'react-router';

export class ClientItem extends Component {

  constructor(props) {
    super(props);
    this.state = {
      redirect: false
    }
  }

  handleOnClick = () => {
    this.setState({redirect: true});
  };

  render() {

    const { client } = this.props;

    if (this.state.redirect) {
      return <Redirect push to={`/admin/${client.get('uuid')}`}/>;
    }

    return (
      <div className='sd-list-item' onClick={this.handleOnClick}>
        <div className="sd-list-item__column"><i className="icon-text"/></div>
        <div className="sd-list-item__column sd-list-item__column--grow sd-list-item__column--no-border">
          <div className="sd-list-item__row">
            <span className="sd-overflow-ellipsis sd-list-item--element-grow">{client.get('name')}</span>
          </div>
        </div>
      </div>
    )
  }
}
