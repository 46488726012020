import React from 'react';
import classNames from 'classnames';

function pagination(c, m) {
    var current = c,
        last = m,
        delta = 4,
        left = current - delta,
        right = current + delta + 1,
        range = [],
        rangeWithDots = [],
        l;

    for (let i = 1; i <= last; i++) {
        if (i === 1 || i === last || (i >= left && i < right)) {
            range.push(i);
        }
    }

    for (let i of range) {
        if (l) {
            if (i - l === 2) {
                rangeWithDots.push(l + 1);
            } else if (i - l !== 1) {
                rangeWithDots.push('...');
            }
        }
        rangeWithDots.push(i);
        l = i;
    }

    return rangeWithDots;
}

export const Paginator = ({ previous, next, current, all, paginatorClick }) => {

    const pages = pagination(current, all)
    
    const buttonClass = {
        'btn': true,
        'btn--primary': true,
        'btn--icon-only': true,
        'btn--hollow': true
    }

    const prevClass = classNames({...buttonClass, 'btn--disabled': !previous})
    const nextClass = classNames({...buttonClass, 'btn--disabled': !next})

    return (
        <div className='paginator'>
            <span className={prevClass} onClick={() => paginatorClick(previous)}>
                <i className="icon-chevron-left-thin" />
            </span>
            {pages.map(page => {

                const pageClass = {
                    'btn': true,
                    'btn--primary': true,
                    'btn--hollow': page !== current
                }

                return (<span key={page} className={classNames(pageClass)} onClick={() => page !== "..." ? paginatorClick(page) : null}>
                   {page}
                </span>)
            })}
            <span className={nextClass} onClick={() => paginatorClick(next)}>
                <i className="icon-chevron-right-thin" />
            </span>
        </div>
)};