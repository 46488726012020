import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { 
  fetchProducts, 
  cleanProducts, 
  addProducts, 
  updateProducts, 
  removeProducts 
} from 'state/actions/products';
import { resetSearchQuery } from "state/actions/search";
import { resetApp } from "state/actions/app";

import ModalNews from './modal';
import { NewsList } from "./NewsList";
import { Websocket } from "tools/websocket";
import SearchInput from 'pages/search/SearchInput';
import SearchFilter from 'pages/search/SearchFilter';
import ProductSelect from './ProductSelect'

class ProductListPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      modal: false
    };
    this.socket = new Websocket('/news/', news => this.websocketHandler(news))
  }

  websocketHandler = (news) => {
    if (news.type === 'new' && news.created) {
      this.props.addProducts(news)
    } if (news.type === 'new' && !news.created) {
      this.props.updateProducts(news)
    } else if (news.type === 'canceled') {
      this.props.removeProducts(news)
    }
  }

  componentWillMount() {
    this.props.fetchProducts();
  }

  componentWillUnmount() {
    this.props.resetSearchQuery();
    this.props.resetApp();
    this.props.cleanProducts();
    this.socket.close()
  }

  openModal = (news) => {
    this.setState({
      modal: this.props.products.getIn(['data', 'entities', 'news', news])
    });
  };

  closeModal = () => this.setState({modal: false});

  render() {

    const { products, auth, app } = this.props;

    let searchVisible = classNames({
      'row': true,
      'sd-flex-no-wrap': true,
      'row-filter-visible': app.get('show_subnav_search')
    });

    return(
      <div className='sd-container__with-subnav'>
        <div className="subnav">
          <SearchInput toggable = {true} />
          <ProductSelect
            current={this.context.t("all products")}
            list={products}
          />
        </div>
        <SearchFilter show = {app.get('show_subnav_search')}/>
        <div className={searchVisible}>
          {products.get('data') && products.getIn(['data', 'entities', 'news']) && products.getIn(['data', 'result']).map(product =>
            <div className="col-md-3" key={product}>
              <div className='sd-list-header'>
                <div className='sd-list-header__name'>
                  <Link to={`/rss/${product}/?token=${auth.getIn(['user', 'rsskey'])}`} target="_blank" className="btn btn--warning btn--small btn--icon-only btn--hollow">
                    <i className='icon-broadcast'/>
                  </Link>
                  <Link to={`/product/${product}`} >{ products.getIn(['data', 'entities', 'products', product, 'name']) }</Link>
                </div>
              </div>
              <NewsList
                news={products.getIn(['data', 'entities', 'products', product, 'news'])}
                newsList={products.getIn(['data', 'entities', 'news'])}
                openNewsModal={this.openModal}
              />
              <div className='list-footer'>
                <Link className="btn btn--hollow btn--primary" to={`/product/${product}`} >{this.context.t("more news")} <i className="icon-chevron-right-thin"/></Link>
              </div>
            </div>
          )}
        </div>
        {this.state.modal &&
          <ModalNews
            news={this.state.modal}
            closeModal={this.closeModal}
          />
        }
      </div>
    )
  }
}

ProductListPage.contextTypes = {
  t: PropTypes.func
};

function mapStateToProps(state) {
  return {
    products: state.get('products'),
    auth: state.get('auth'),
    app: state.get('app')
  }
}

const mapDispatchToProps = {
  fetchProducts,
  cleanProducts,
  addProducts,
  updateProducts, 
  removeProducts,
  resetApp,
  resetSearchQuery
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductListPage)
