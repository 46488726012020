import React from 'react'
import { NewsItem } from "./NewsItem";
import classNames from "classnames";

export const NewsList = ({ news, newsList, openNewsModal, showFilter }) => {

  let listClass = classNames({
    'sd-list-item-group': true,
    'sd-shadow--z2': true,
    'filter-active': showFilter
  });

  return (
    <div className={listClass}>
      {news.map(n =>
        <NewsItem key={n} news={newsList.get(n)} openNewsModal={openNewsModal} />
      )}
    </div>
  )
};
